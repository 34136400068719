<template>
    <div class="flex flex-row items-center justify-between font-primary py-2 px-7 bg-main-blue">
        <a :href="URL" class="flex items-center">
            <img class="w-32 sm:w-44" src="../assets/images/east_80_black.png" alt="">
        </a>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'

const URL = ref('')

onMounted(() => {
    URL.value = process.env.VUE_APP_URL
})
</script>