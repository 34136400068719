import east80Api from "@/utils/api";

const workoutModule = {
  namespaced: true,
  state: {
    status: false,
  },

  mutations: {
    SET_STATUS(state, status) {
      state.status = status;
    },
  },

  actions: {
    async sendUserDetails({ commit }, data) {
      try {
        const response = await east80Api.post("/personalized-workout", data);

        const status = response.data.success;

        commit("SET_STATUS", status);
      } catch (error) {
        console.log(error);
      }
    },
  },
};

export default workoutModule;
