import east80Api from "@/utils/api";

const paymentModule = {
    namespaced: true,

    state: {
        stkPushStatus: false,
        paymentStatus: false,
        stkResponse: {},
        paymentResponse: {},
    },

    mutations: {
        SET_STK_PUSH_STATUS(state, status) {
            state.stkPushStatus = status;
        },
        SET_PAYMENT_STATUS(state, status) {
            state.paymentStatus = status;
        },
        SET_STK_RESPONSE(state, response) {
            state.stkResponse = response;
        },
        SET_PAYMENT_RESPONSE(state, response) {
            state.paymentResponse = response;
        },
    },

    actions: {
        async generateStkPush({ commit }, payment) {
            try {
                const response = await east80Api.post(`/stk-push`, payment);
                console.log(response.data);

                const success = response.data.success;

                if (success) {
                    const paymentDetails = response.data.data

                    commit("SET_STK_PUSH_STATUS", true);
                    commit("SET_STK_RESPONSE", paymentDetails);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async checkPaymentStatus({ commit }, checkoutId) {
            try {
                const response = await east80Api.post(`/stk-response`, {
                    checkoutId: checkoutId
                });
                console.log(response.data);

                const success = response.data.success;

                if (success) {
                    let paymentResponse = response.data.data;
                    commit("SET_PAYMENT_STATUS", true);
                    commit("SET_PAYMENT_RESPONSE", paymentResponse);
                }
            } catch (error) {
                console.log(error);
            }
        }
    },
};

export default paymentModule;
