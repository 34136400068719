import { createRouter, createWebHistory } from "vue-router";
import UserDetailsView from "../views/UserDetailsView.vue";

const routes = [
  {
    path: "/u/:code",
    name: "user",
    component: UserDetailsView,
    props: true,
  },

  {
    path: "/workout/u/:code",
    name: "user-workout",
    component:  () => import( /* webpackChunkName: "UserWorkout" */ "../views/UserWorkout.vue"),
  },

  {
    path: "/book-a-session/:code",
    name: "book-a-session",
    component:  () => import( /* webpackChunkName: "UbaView" */ "../views/UbaView.vue"),
  },

  {
    path: "/subscribe/hybrid-program/:code",
    name: "subscribe-hybrid-program",
    component:  () => import( /* webpackChunkName: "SubscribeHybridProgram" */ "../views/SubscribeHybridProgram.vue"),
  },

  {
    path: "/payment",
    name: "payment",
    component:  () => import( /* webpackChunkName: "Payment" */ "../components/Payments/CheckoutComponent.vue"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../components/NotFound.vue"),
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
