import { createStore } from "vuex";
import east80Api from "@/utils/api";
import workoutModule from "./module/workoutModule";
import ubaModule from "./module/ubaModule";
import userModule from "./module/userModule";
import paymentModule from "./module/paymentModule";

export default createStore({
  modules: {
    workoutModule: workoutModule,
    ubaModule: ubaModule,
    userModule: userModule,
    paymentModule: paymentModule,
  },
});
