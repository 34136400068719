import east80Api from "@/utils/api";

const useModule = {
    namespaced: true,

    state: {
        status: false,
        userDetails: null,
    },

    mutations: {
        SET_STATUS(state, status) {
            state.status = status;
        },
        SET_USER_DETAILS(state, userDetails) {
            state.userDetails = userDetails;
        },
    },

    actions: {
        async getHybridProgram({ commit }, u) {
            try {
                const response = await east80Api.post(`/get-hybrid-program`, u);

                // const userDetails = response.data;
                const success = response.data.success;

                if (success == true) {
                    console.log(response.data);

                    // commit("SET_USER_DETAILS", userDetails);
                    commit("SET_STATUS", success);
                }
            } catch (error) {
                console.log(error);
            }
        },
    },

    getters: {},
};
export default useModule;
