<template>
    <div v-show="submitted"
        class="relative z-10 flex flex-col font-sans  font-bold w-full min-h-[50vh] justify-center items-center">
        <img v-show="success" class="w-full h-56 object-contain" src="../assets/images/success.gif" alt="">
        <h3 v-show="redirecting">Redirecting you to your profile...</h3>
    </div>
    <div v-show="!submitted" class="flex flex-col p-2 sm:p-16 relative items-center justify-center min-h-[80vh] z-10">
        <div class="m-auto font-primary bg-gray-100 p-10 lg:px-32 lg:py-10 rounded-md shadow-md shadow-black">

            <div v-show="selectingGender" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">What's your gender</h3>
                <div class="flex flex-row gap-8">
                    <div @click="selectGender('male')"
                        class="cursor-pointer flex flex-col items-center border-gray-400 hover:bg-main-blue hover:text-white p-3 rounded-sm w-[100px] border"
                        :class="{ 'bg-main-blue text-white': gender === 'male' }">
                        <img class="w-10 object-contain" src="../assets/images/male.png" alt="">
                        <label for="male" class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Male</label>
                    </div>
                    <div @click="selectGender('female')"
                        class="cursor-pointer flex flex-col items-center border-gray-400 hover:bg-main-blue hover:text-white p-3 rounded-sm w-[100px] border "
                        :class="{ 'bg-main-blue text-white': gender === 'female' }">
                        <img class="w-10 object-contain" src="../assets/images/female.png" alt="">
                        <label for="male" class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Female</label>
                    </div>
                </div>

                <h4 @click="goToSelectBodyType"
                    class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                    NEXT
                </h4>
                <a :href="URL"
                    class="bg-main-blue text-white text-sm font-primary mt-5 py-1 px-4 rounded cursor-pointer">
                    GO BACK TO PROFILE
                </a>
            </div>

            <div v-show="selectingBodyType" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">Choose your current body type</h3>
                <div class="flex flex-col gap-8">
                    <div @click="selectBodyType('slim')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': bodyType === 'slim' }">
                        <label for="male" class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Slim</label>
                        <img v-show="gender == 'male'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/slim.png" alt="">
                        <img v-show="gender == 'female'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/f-slim.png" alt="">
                    </div>
                    <div @click="selectBodyType('average')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': bodyType === 'average' }">
                        <label for="male" class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Average</label>
                        <img v-show="gender == 'male'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/average.png" alt="">
                        <img v-show="gender == 'female'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/f-average.png" alt="">
                    </div>
                    <div @click="selectBodyType('heavy')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': bodyType === 'heavy' }">
                        <label for="male" class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Heavy</label>
                        <img v-show="gender == 'male'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/heavy.png" alt="">
                        <img v-show="gender == 'female'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/f-heavy.png" alt="">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToSelectGender"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToSelectTargetBodyType"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingTargetBodyType" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">Choose your target body type</h3>
                <div class="flex flex-col gap-8">

                    <div v-show="gender == 'female'" @click="selectTargetBody('tone')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': targetBodyType === 'tone' }">
                        <label class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Tone</label>
                        <img v-show="gender == 'female'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/f-fit-t.png" alt="">
                    </div>

                    <div @click="selectTargetBody('athlete')" v-show="gender == 'male'"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': targetBodyType === 'athlete' }">
                        <label v-show="gender == 'male'"
                            class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Athlete</label>
                        <img v-show="gender == 'male'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/athletic.png" alt="">
                    </div>

                    <div @click="selectTargetBody('average')" v-show="gender == 'female'"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': targetBodyType === 'average' }">
                        <label v-show="gender == 'female'"
                            class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Average</label>
                        <img v-show="gender == 'female'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/f-average-1.png" alt="">
                    </div>

                    <div @click="selectTargetBody('fit')" v-show="gender == 'male'"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': targetBodyType === 'fit' }">
                        <label v-show="gender == 'male'"
                            class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Fit</label>
                        <img v-show="gender == 'male'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/fit.png" alt="">
                    </div>

                    <div @click="selectTargetBody('body-build')" v-show="gender == 'female'"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': targetBodyType === 'body-build' }">
                        <label v-show="gender == 'female'" class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Body
                            build</label>
                        <img v-show="gender == 'female'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/f-body-build.png" alt="">
                    </div>

                    <div v-show="gender == 'male'" @click="selectTargetBody('bulky')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': targetBodyType === 'bulky' }">
                        <label class="text-md sm:text-lg font-bold mb-2 cursor-pointer">Bulky</label>
                        <img v-show="gender == 'male'" class="w-[100px]  object-contain"
                            src="../assets/images/body-types/bulky.png" alt="">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToSelectBodyType"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToSelectFitnessLevel"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingFitnessLevel" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">Choose your current fitness level</h3>
                <div class="flex flex-col gap-8">
                    <div @click="selectFitnessLevel('beginner')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': fitnessLevel === 'beginner' }">
                        <label for="beginner">Beginner</label>
                    </div>
                    <div @click="selectFitnessLevel('intermediate')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': fitnessLevel === 'intermediate' }">
                        <label for="intermediate">Intermediate</label>
                    </div>
                    <div @click="selectFitnessLevel('advanced')"
                        class="cursor-pointer flex flex-row justify-between items-center shadow-lg hover:bg-main-blue hover:text-white p-3 rounded-md w-[230px] sm:w-[300px] border"
                        :class="{ 'bg-main-blue text-white': fitnessLevel === 'advanced' }">
                        <label for="advanced">Advanced</label>
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToSelectTargetBodyType"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToEnterWorkoutTime"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingWorkoutTime" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">How long do you want your workouts to be (In minutes)</h3>
                <div class="flex flex-col gap-5">
                    <label for="workout-time" class="text-md sm:text-lg font-semibold">Workout time in a day</label>
                    <input type="number" name="workout-time" id="workout-time"
                        class="p-2 border border-gray-400 rounded-md" v-model="workoutTime"
                        placeholder="Enter workout time" />
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToSelectFitnessLevel"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToWorkoutChallenges"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingWorkoutChallenges" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">Did you face any challenges in your previous fitness
                    endeavors?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="lack-motivation" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="challenges.lackMotivation" @change="removeNone('challenges')">
                        <label for="lack-motivation" class="text-md sm:text-lg font-semibold">Lack of motivation</label>
                    </div>
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="lack-time" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="challenges.lackTime" @change="removeNone('challenges')">
                        <label for="lack-time" class="text-md sm:text-lg font-semibold">Lack of time</label>
                    </div>
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="lack-resources" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="challenges.lackResources" @change="removeNone('challenges')">
                        <label for="lack-resources" class="text-md sm:text-lg font-semibold">Lack of resources</label>
                    </div>
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="bad-coaching" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="challenges.badCoaching" @change="removeNone('challenges')">
                        <label for="bad-coaching" class="text-md sm:text-lg font-semibold">Bad coaching</label>
                    </div>

                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="none" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="challenges.none" @change="clearAll('challenges')">
                        <label for="none" class="text-md sm:text-lg font-semibold">None of the above</label>
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToEnterWorkoutTime"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToExercises"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingExercises" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">What are some exercises you like doing?</h3>
                <div class="flex flex-col gap-5">

                    <div class="flex flex-col items-center gap-x-2">
                        <input type="text" id="exercises" v-model="exercises"
                            class="text-lg px-3 py-2 rounded-md outline-main-blue bg-white border border-main-blue w-full ">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToWorkoutChallenges"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToGoals"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingGoals" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">What are your goals?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="habbit" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="goals.habbit" @change="removeNone('goals')">
                        <label for="habbit" class="text-md sm:text-lg font-semibold">Form a physical habbit</label>
                    </div>
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="weight" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="goals.lackResources" @change="removeNone('goals')">
                        <label for="weight" class="text-md sm:text-lg font-semibold">Loss weight</label>
                    </div>
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="discipline" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="goals.discipline" @change="removeNone('goals')">
                        <label for="discipline" class="text-md sm:text-lg font-semibold">Self-discipline</label>
                    </div>
                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="health" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="goals.health" @change="removeNone('goals')">
                        <label for="health" class="text-md sm:text-lg font-semibold">Feel healthier</label>
                    </div>

                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="stress" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="goals.stress" @change="removeNone('goals')">
                        <label for="stress" class="text-md sm:text-lg font-semibold">Reduce stress</label>
                    </div>

                    <div class="flex flex-row items-center gap-x-2">
                        <input type="checkbox" id="na" class="text-lg border-2 border-main-blue h-6 w-6"
                            v-model="goals.none" @change="clearAll('goals')">
                        <label for="na" class="text-md sm:text-lg font-semibold">None of the above</label>
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 v-show="!isLoading" @click="goToExercises"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 v-show="!isLoading" @click="goToMeals"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingMeals" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">How many meals do you have in a day?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-col items-center gap-x-2">
                        <input type="text" id="meals"
                            class="text-lg px-3 py-2 rounded-md outline-main-blue bg-white border border-main-blue w-full "
                            v-model="meals">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToGoals"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToWaterConsumption"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingWaterConsumption" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">How much water do you consume in a day?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-col items-center gap-x-2">
                        <input type="text" id="water-consumption"
                            class="text-lg px-3 py-2 rounded-md outline-main-blue bg-white border border-main-blue w-full "
                            v-model="waterConsumption">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToMeals"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToSleepTime"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingSleepTime" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">How many hours of sleep do you get in a day?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-col items-center gap-x-2">
                        <input type="text" id="sleep-time"
                            class="text-lg px-3 py-2 rounded-md outline-main-blue bg-white border border-main-blue w-full "
                            v-model="sleepTime">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToWaterConsumption"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToOccupation"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>
                </div>
            </div>

            <div v-show="selectingOccupation" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">What do you do for a living?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-col items-center gap-x-2">
                        <input type="text" id="occupation"
                            class="text-lg px-3 py-2 rounded-md outline-main-blue bg-white border border-main-blue w-full "
                            v-model="occupation">
                    </div>
                </div>
                <div class="flex flex-row gap-10">
                    <h4 @click="goToSleepTime"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <h4 @click="goToInjuries"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        NEXT
                    </h4>

                </div>
            </div>

            <div v-show="selectingInjuries" class="flex flex-col items-center gap-5 min-w-[200px]">
                <h3 class="font-semibold text-md sm:text-lg">Do you have any injuries or medical conditions?</h3>
                <div class="flex flex-col gap-5">
                    <div class="flex flex-col items-center gap-x-2">
                        <input type="text" id="injuries"
                            class="text-lg px-3 py-2 rounded-md outline-main-blue bg-white border border-main-blue w-full "
                            v-model="injuries">
                    </div>
                </div>
                <div class="flex flex-col gap-10">
                    <h4 @click="goToOccupation"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        BACK
                    </h4>
                    <button v-show="!isLoading" type="submit" @click="submitDetails"
                        class="bg-gray-500 hover:bg-main-blue text-white font-bold py-2 px-4 rounded cursor-pointer">
                        SUBMIT
                    </button>
                    <div v-show="isLoading">
                        <LoadingComponent />
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="flex justify-center items-center">
        <img class="fixed left-0 top-[25vh] w-4/5 right-0 mx-auto z-0 opacity-10" src="../assets/images/logo-white.png"
            alt="logo" />
    </div>
</template>

<script setup>
import LoadingComponent from './LoadingComponent.vue';

import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex';

const route = useRoute()
const store = useStore()

const URL = ref('')

const gender = ref('')
const bodyType = ref('')
const targetBodyType = ref('')
const fitnessLevel = ref('')
const workoutTime = ref(0)
const goals = ref({})
const challenges = ref({})
const exercises = ref('')
const sleepTime = ref('')
const injuries = ref('')
const occupation = ref('')
const waterConsumption = ref('')
const meals = ref('')

const u = ref('')

const success = ref(false)
const redirecting = ref(false)
const submitted = ref(false)
const isLoading = ref(false)

const selectingGender = ref(true)
const selectingBodyType = ref(false)
const selectingTargetBodyType = ref(false)
const selectingFitnessLevel = ref(false)
const selectingWorkoutTime = ref(false)
const selectingWorkoutChallenges = ref(false)
const selectingGoals = ref(false)
const selectingExercises = ref(false)
const selectingSleepTime = ref(false)
const selectingInjuries = ref(false)
const selectingOccupation = ref(false)
const selectingWaterConsumption = ref(false)
const selectingMeals = ref(false)

onMounted(() => {
    URL.value = process.env.VUE_APP_URL
    const code = route.params.code
    u.value = code
})

const selectGender = (g) => {
    gender.value = g;
}

const selectBodyType = (b) => {
    bodyType.value = b;
}

const selectTargetBody = (b) => {
    targetBodyType.value = b;
}

const selectFitnessLevel = (f) => {
    fitnessLevel.value = f;
}

const goToSelectGender = () => {
    selectingGender.value = true
    selectingBodyType.value = false
}

const goToSelectTargetBodyType = () => {
    if (bodyType.value !== '') {
        selectingTargetBodyType.value = true
        selectingBodyType.value = false
        selectingFitnessLevel.value = false
    }
}

const goToSelectBodyType = () => {
    if (gender.value !== '') {
        selectingBodyType.value = true
        selectingTargetBodyType.value = false
        selectingGender.value = false
    }
}
const goToSelectFitnessLevel = () => {
    if (targetBodyType.value !== '') {
        selectingFitnessLevel.value = true
        selectingTargetBodyType.value = false
        selectingWorkoutTime.value = false
    }
}

const goToEnterWorkoutTime = () => {
    if (fitnessLevel.value !== '') {
        selectingWorkoutTime.value = true
        selectingFitnessLevel.value = false
        selectingWorkoutChallenges.value = false
    }
}

const goToWorkoutChallenges = () => {
    if (workoutTime.value > 0) {
        selectingWorkoutChallenges.value = true
        selectingWorkoutTime.value = false
        selectingGoals.value = false
    }
}

const goToExercises = () => {
    if (challenges.value != '') {
        selectingWorkoutChallenges.value = false
        selectingExercises.value = true
        selectingGoals.value = false
    }
}

const goToGoals = () => {
    if (exercises.value != '') {
        selectingGoals.value = true
        selectingExercises.value = false
        selectingMeals.value = false
    }
}

const goToMeals = () => {
    if (Object.values(challenges.value).includes(true)) {
        selectingMeals.value = true
        selectingGoals.value = false
        selectingWaterConsumption.value = false
    }
}

const goToWaterConsumption = () => {
    if (meals.value != '') {
        selectingWaterConsumption.value = true
        selectingMeals.value = false
        selectingSleepTime.value = false
    }
}

const goToSleepTime = () => {
    if (waterConsumption.value != '') {
        selectingSleepTime.value = true
        selectingWaterConsumption.value = false
        selectingOccupation.value = false
    }
}

const goToOccupation = () => {
    if (sleepTime.value != '') {
        selectingOccupation.value = true
        selectingSleepTime.value = false
        selectingInjuries.value = false
    }
}

const goToInjuries = () => {
    if (occupation.value != '') {
        selectingInjuries.value = true
        selectingOccupation.value = false
    }
}

const submitDetails = async () => {
    if (occupation.value == '') {
        return
    }
    isLoading.value = true
    const userDetails = {
        user: u.value,
        gender: gender.value,
        body_type: bodyType.value,
        target_body_type: targetBodyType.value,
        fitness_level: fitnessLevel.value,
        workout_time: workoutTime.value.toString(),
        workout_goals: Object.keys(goals.value).filter(key => goals.value[key]).join(", "),
        workout_challenges: Object.keys(challenges.value).filter(key => challenges.value[key]).join(", "),
        exercises_they_like: exercises.value,
        average_sleep_hours: sleepTime.value,
        injuries_and_medical_conditions: injuries.value,
        occupation: occupation.value,
        average_water_consumption: waterConsumption.value,
        average_meals_per_day: meals.value
    }

    await store.dispatch('workoutModule/sendUserDetails', userDetails)

    const status = store.state.workoutModule.status

    if (status == true) {
        submitted.value = true
        success.value = true
        setTimeout(() => {
            success.value = false
            redirecting.value = true
        }, 900)
        setTimeout(() => {
            window.location.href = URL.value + '/profile';
        }, 1000)
    } else {
        submitted.value = false
        isLoading.value = false
        alert('An error occurred. Please try again')
    }
}

const clearAll = (option) => {
    if (option === 'challenges') {
        let currentNone = challenges.value.none

        challenges.value = {
            lackMotivation: false,
            lackTime: false,
            lackResources: false,
            badCoaching: false,
            none: currentNone
        }
    }

    if (option === 'goals') {
        let currentNone = goals.value.none
        goals.value = {
            habbit: false,
            weight: false,
            discipline: false,
            health: false,
            stress: false,
            none: currentNone
        }
    }
}

const removeNone = (option) => {
    if (option === 'challenges') {
        challenges.value.none = false
    } else {
        goals.value.none = false
    }
}

</script>
